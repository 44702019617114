.header{
    padding: 20px;
    width: 100%;
    background-color: #ccc;
}

.h1{
    font-size: 34px;
}

.h2{
    font-size: 26px;
}

.h3{
    font-size: 20px;
}

.texto{
    font-size: 16px;
}

input {
    border: solid 1px #f6f6f6;
}

textarea{
    border: solid 1px #f6f6f6;
}