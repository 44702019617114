body {
 color: #555;
 background-color: #e2e2e2;
}

.custom-primary-color-text{
 color: #4285F4;
}

.breadcrumb{
 padding: 0;
 font-size: 12px;
}

/* Toast */
.Toastify__toast--error {
 background-color: #ff4444 !important;
}
.Toastify__toast--success {
 background-color: #00C851 !important;

}
.Toastify__toast--warning {
 background-color: #FF8800 !important;

}
/********/

.invalid-input > input{
 border-bottom: solid 1px #CC0000 !important;
}
.invalid-input > label{
 color: #CC0000;
}
.invalid-select > div > div{
 border-bottom: solid 1px #CC0000 !important;
}
.invalid-select > label{
 color: #CC0000 !important;
}

.background-site{
 background: url("assets/background-site.png") no-repeat center center;
 background-size: cover;
 height: 900px;
}
.menu-home > a{
 color: #fff !important;
 min-width: 100px;
}
.navbar-site{
 box-shadow: 0 0 0 0 !important;
}
.btn-signup{
 padding-left: 50px !important;
 padding-right: 50px !important;
 border-radius: 20px;
}

.errors-box{

}


.md-form>input[type="date"]:not(.browser-default)+label {
 transform: translateY(-14px) scale(0.8);
}

.MuiInputLabel-shrink {
 transform: translate(0, 15px) scale(0.85) !important;
}

.custom-badge-warning{
 color: #333 !important;
}

.tr-concomitante td{
 border-top: 0;
 padding: 0 15px !important;
 font-size: 1px;
}

.erro-campo-invalido{
 color: #ff3547;
 font-size: 12px;
}
